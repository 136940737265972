import React, {useEffect} from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
// Material UI
import { makeStyles, ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import styled from 'styled-components';

import { Row, Col, Container } from 'react-bootstrap';

import 'fontsource-roboto';

import blue from '@material-ui/core/colors/green';

import lottie from "lottie-web";
import animation from "../static/assets/lotties/lf30_editor_ir3ah05a.json";


// Logo

import Logo from '../../static/assets/images/logo_BD.svg';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: blue[500],
        },
    },
});

const AnimationContainerDiv = styled.div`
    max-width: 500px;
    width:100%;
    height: 50vh;
    margin: 0 auto; 
    pointer-events: none;
`;

export default function ErrorPage() {

    let animationContainer = React.createRef();
    // Lottie Options

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        lottie.loadAnimation({
            container: animationContainer.current,
            animationData: animation
        })
    }, []);

    return <ThemeProvider theme={theme}>
        <Container style={{height: '100vh'}}>
        
            <Row className="h-100 text-center">
                <Col xs={{width: 4}} className="h-100 text-center h-100 d-flex flex-column justify-content-center">
                    <AniLink cover direction="down" bg="#161923" to="/inside"> <img width="200" className="img-fluid mx-auto" src={Logo} alt="404 Logo" /> </AniLink>
                    <h1 style={{ fontFamily: 'arial' }}><span style={{color: 'lightBlue'}}>404</span></h1>
                    <h5 style={{ fontFamily: 'arial' }}>PAGE NOT FOUND.</h5>
                <AnimationContainerDiv className="animation-container" ref={animationContainer}/>
                </Col>
            </Row>
        </Container>
    </ThemeProvider>
}
